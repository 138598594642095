import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import VueKeycloakJs from '@dsb-norge/vue-keycloak-js'
import { createPinia, PiniaVuePlugin } from 'pinia'
import axios from 'axios'
import i18n from './i18n'

Vue.config.productionTip = false

function tokenInterceptor () {
  axios.interceptors.request.use(
    (config) => {
      if (Vue.prototype.$keycloak.authenticated) {
        config.headers.Authorization = `Bearer ${Vue.prototype.$keycloak.token}`
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )
}

Vue.use(PiniaVuePlugin)
const pinia = createPinia()

Vue.use(VueKeycloakJs, {
  init: {
    // Use 'login-required' to always require authentication
    // If using 'login-required', there is no need for the router guards in router.js
    // onLoad: 'login-required',
    onLoad: 'check-sso',
    checkLoginIframe: false
  },

  // Configuration values are used from '.env' files
  config: {
    url: window.VUE_APP_KEYCLOAK_URL ? window.VUE_APP_KEYCLOAK_URL : process.env.VUE_APP_KEYCLOAK_URL,
    realm: window.VUE_APP_KEYCLOAK_REALM ? window.VUE_APP_KEYCLOAK_REALM : process.env.VUE_APP_KEYCLOAK_REALM,
    clientId: window.VUE_APP_KEYCLOAK_CLIENT ? window.VUE_APP_KEYCLOAK_CLIENT : process.env.VUE_APP_KEYCLOAK_CLIENT
  },

  onReady: () => {
    // console.log('KEYCLOAK URL', window.VUE_APP_KEYCLOAK_URL ? window.VUE_APP_KEYCLOAK_URL : process.env.VUE_APP_KEYCLOAK_URL)
    // console.log('KEYCLOAK REALM', window.VUE_APP_KEYCLOAK_REALM ? window.VUE_APP_KEYCLOAK_REALM : process.env.VUE_APP_KEYCLOAK_REALM)
    // console.log('KEYCLOAK CLIENT', window.VUE_APP_KEYCLOAK_CLIENT ? window.VUE_APP_KEYCLOAK_CLIENT : process.env.VUE_APP_KEYCLOAK_CLIENT)
    // console.log('VUE_APP_PROFILE', window.VUE_APP_PROFILE)

    tokenInterceptor()
    new Vue({
      router,
      vuetify,
      i18n,
      pinia,
      render: (h) => h(App)
    }).$mount('#app')
  }
})

Vue.prototype.$userFeatures = []
