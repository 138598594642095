<template>
  <v-card v-if="showComponent()">
    <v-card-title>{{ $t('grazingStillOpen.title') }}</v-card-title>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">{{ $t('grazingStillOpen.zoneName') }} </th>
              <th class="text-left">{{ $t('grazingStillOpen.openDuration') }} </th>
              <th>{{ $t('terms.tableHeaders.actions') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(zone, index) in zonesStillOpen" :key="index">
              <td>{{ zone.zoneName }}</td>
              <td>{{ openDuration(zone) }}</td>
              <td>
                <ZoneAccessIcon :zone="zone" @reload="findZonesStillOpen" />
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import ZoneAccessIcon from '@/views/grazing/ZoneAccessIcon.vue'
import formatting from '@/mixins/formatting'

export default {
  name: 'GrazingStillOpen',
  components: { ZoneAccessIcon },
  mixins: [formatting],

  data: () => ({
    zonesStillOpen: []
  }),
  methods: {
    showComponent () {
      return this.zonesStillOpen != null && this.zonesStillOpen.length > 0
    },

    openDuration (zone) {
      const now = new Date()
      const openDuration = now - new Date(zone.accessOpened)
      return this.formatDuration(openDuration / 1000)
    },

    findZonesStillOpen () {
      fetch('/api/grazing/still-open', {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then((response) => response.json())
        .then((data) => {
          if (data != null) {
            this.zonesStillOpen = data
          }
        })
    }
  },
  beforeMount () {
    this.findZonesStillOpen()
  }
}
</script>
