import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import { userAuthorized } from '../helpers'
import i18n from '@/i18n'
import grazingRoutes from './grazing'
import butcherRoutes from './butcher'
import cropRoutes from './crop'
import irrigationRoutes from './irrigation'
import livestockRoutes from './livestock'
import accountingRoutes from './accounting'
import tasksRoutes from './tasks'
import zonesRoutes from './zones'
import salesRoutes from './sales'
import stripsRoutes from './strips'
import settingsRoutes from './settings'
import communityRoutes from './community'
import { forceArray } from '@/helpers'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'LandingPage',
    component: () => import('@/views/LandingPage.vue')
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      title: i18n.t('mainMenu.home'),
      requiresAuth: true,
      role: ['granja-user', 'granja-admin']
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('@/views/Dashboard.vue'),
    meta: {
      title: i18n.t('mainMenu.dashboard'),
      requiresAuth: true,
      role: ['granja-user', 'granja-admin']
    }
  },
  grazingRoutes,
  butcherRoutes,
  cropRoutes,
  irrigationRoutes,
  livestockRoutes,
  accountingRoutes,
  tasksRoutes,
  zonesRoutes,
  salesRoutes,
  stripsRoutes,
  settingsRoutes,
  communityRoutes,

  {
    path: '/customers',
    name: 'Customers',
    meta: {
      title: i18n.t('mainMenu.customers'),
      requiresAuth: true,
      role: ['granja-user', 'granja-admin']
    },
    component: () => import('@/views/Customers.vue')
  },
  {
    path: '/customers/:customerId',
    name: 'CustomerDetail',
    meta: {
      title: 'Customer Detail',
      requiresAuth: true,
      role: ['granja-user', 'granja-admin']
    },
    component: () => import('@/views/customer/Detail.vue')
  },

  {
    path: '/timesheet',
    name: 'Timesheet',
    meta: {
      title: i18n.t('mainMenu.timesheet'),
      requiresAuth: true,
      role: ['granja-user', 'granja-admin']
    },
    component: () => import(/* webpackChunkName: "timesheet" */ '@/views/timesheet/Timesheet.vue')
  },

  {
    path: '/shipping',
    name: 'Shipping',
    meta: {
      title: i18n.t('mainMenu.shipping'),
      requiresAuth: true,
      role: ['granja_shipper', 'granja-admin']
    },
    component: () => import(/* webpackChunkName: "shipping" */ '@/views/shipping/Shipping.vue')
  },
  {
    path: '/big-image/:imagePath',
    name: 'BigImage',
    meta: {
      title: 'Big Image',
      requiresAuth: true,
      role: ['granja-user', 'granja-admin']
    },
    component: () => import('@/views/tasks/BigImage.vue')
  },
  {
    path: '/signup',
    name: 'Signup',
    meta: {
      title: 'Signup',
      requiresAuth: false
    },
    component: () => import('@/views/signup/Signup.vue')
  },
  {
    path: '/signup/account',
    name: 'SignupAccount',
    meta: {
      title: 'Account',
      requiresAuth: false
    },
    component: () => import('@/views/signup/Account.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

function sleep (ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

function goOnToPage (toRoute, next) {
  window.document.title = toRoute.meta && toRoute.meta.title ? toRoute.meta.title : 'Granja EU'
  next()
}

router.beforeEach(async (toRoute, fromRoute, next) => {
  // console.log('toRoute', toRoute)
  if (toRoute.path === '/' && Vue.prototype.$keycloak.authenticated) {
    next('/home')
  } else if (toRoute.matched.some((record) => record.meta.requiresAuth)) {
    // We wait for Keycloak init, then we can call all methods safely
    while (Vue.prototype.$keycloak.createLoginUrl === null) {
      await sleep(100)
    }
    if (Vue.prototype.$keycloak.authenticated) {
      // console.log('Vue.prototype.$userFeatures', Vue.prototype.$userFeatures)

      if (!Vue.prototype.$userFeatures || Vue.prototype.$userFeatures.length === 0) {
        const profile = await Vue.prototype.$keycloak.loadUserProfile()
        Vue.prototype.$userFeatures = forceArray(profile.attributes.tenant_features)
      }

      if (userAuthorized(toRoute)) { goOnToPage(toRoute, next) }
    } else {
      const loginUrl = Vue.prototype.$keycloak.createLoginUrl()
      window.location.replace(loginUrl)
    }
  } else {
    goOnToPage(toRoute, next)
  }
})

export default router
