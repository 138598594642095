<template>
  <span>
    <v-icon v-if="!isCloseDialog" x-large @click="openDialog()">
      mdi-open-in-app
    </v-icon>
    <v-icon v-if="isCloseDialog" x-large @click="openDialog()">
      mdi-close-octagon
    </v-icon>
    <v-dialog v-model="showDialog">
      <v-card>
        <v-card-title>
          <span v-if="!isCloseDialog">{{ $t('zoneAccessIcon.titleOpen') }}</span>
          <span v-if="isCloseDialog">{{ $t('zoneAccessIcon.titleClose') }}</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <span v-if="!isCloseDialog">Are you sure you want to open this zone?</span>
              <span v-if="isCloseDialog">Are you sure you want to close this zone?</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-menu ref="dateMenu" v-model="dateMenu" :close-on-content-click="false" :return-value.sync="pickedDate"
                transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="pickedDate" :label="$t('zoneAccessIcon.date')" prepend-icon="mdi-calendar"
                    readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="pickedDate" :max="maxDate" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="dateMenu = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.dateMenu.save(pickedDate)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col>
              <v-menu ref="timeMenuRef" v-model="timeMenu" :close-on-content-click="false" :nudge-right="40"
                :return-value.sync="pickedTime" transition="scale-transition" offset-y max-width="290px"
                min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field v-model="pickedTime" :label="$t('zoneAccessIcon.time')" readonly v-on="on"></v-text-field>
                </template>
                <v-time-picker v-if="timeMenu" v-model="pickedTime" ampm-in-title full-width
                  @click:minute="$refs.timeMenuRef.save(pickedTime)" format="24hr"></v-time-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="showDialog = false">Cancel</v-btn>
          <v-btn color="primary" @click="performAction(zone)">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
export default {
  name: 'ZoneAccessIcon',
  props: {
    zone: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    dateMenu: false,
    maxDate: new Date().toISOString().substring(0, 10),
    pickedDate: new Date().toISOString().substring(0, 10),

    timeMenu: false,
    timeValue: null,
    showDialog: false
  }),

  computed: {
    isCloseDialog () {
      return this.zone.accessState === 'OPEN'
    },

    pickedTime: {
      get () {
        if (this.timeValue === null) {
          const timeString = new Date().toLocaleString(navigator.language, { hour12: false, hour: '2-digit', minute: '2-digit', second: '2-digit' })
          return timeString
        } else { return this.timeValue }
      },
      set (value) {
        this.timeValue = value
      }
    }
  },

  methods: {
    openDialog () {
      this.showDialog = true
    },

    performAction (item) {
      if (this.isCloseDialog) {
        this.closeZone(item)
      } else {
        this.openZone(item)
      }
      this.showDialog = false
    },

    openZone (item) {
      fetch(`/api/zones/managed-access/${item.id}/open`, {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          date: this.pickedDate,
          time: this.pickedTime
        })
      })
        .then(() => this.$emit('reload'))
    },

    closeZone (item) {
      fetch(`/api/zones/managed-access/${item.id}/close`, {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          date: this.pickedDate,
          time: this.pickedTime
        })
      })
        .then(() => this.$emit('reload'))
    }
  }
}
</script>
