<template>
  <v-card class="myGranja">
    <v-card-title>{{ $t('myGranja.title') }}</v-card-title>
    <v-card-text>
      <ChoresChecklist v-if="hasFeature('chores')" />
      <PersonalTasks v-if="hasFeature('tasks')" />
    </v-card-text>
  </v-card>
</template>

<script>
import userFeatures from '@/mixins/userFeatures'
import ChoresChecklist from './chores/ChoresChecklist.vue'
import PersonalTasks from './tasks/PersonalTasks.vue'

export default {
  name: 'MyGranja',
  components: { ChoresChecklist, PersonalTasks },
  mixins: [userFeatures]
}
</script>

<style scoped>
.myGranja {
  margin-top: 1em;
  margin-bottom: 1em;
  background-color: rgb(214, 253, 214);
}
</style>
