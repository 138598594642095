<template>
  <div>
    <v-simple-table v-if="tasks.length > 0">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">{{ $t('chores.tableHeaders.choreDueTime') }} </th>
            <th class="text-left">{{ $t('chores.tableHeaders.choreSubject') }} </th>
            <th class="text-left">{{ $t('terms.tableHeaders.actions') }} </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in tasks" :key="index" :class="{ 'done': item.done, 'overdue': isOverdue(item) }">
            <td>{{ formatTime(item.dueTime) }}</td>
            <td>{{ item.choreSubject }}</td>
            <td>
              <div v-if="actionsInactive(item)">
                <v-icon large @click="markAsDone(item)" color="grey">mdi-check</v-icon>
                <v-icon large @click="markAsNotDone(item)" color="grey">mdi-close-circle-outline</v-icon>
              </div>
              <div v-else>
                <v-icon large @click="markAsDone(item)" color="primary">mdi-check</v-icon>
                <v-icon large @click="markAsNotDone(item)" color="secondary">mdi-close-circle-outline</v-icon>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div v-else>
      <p>{{ $t('chores.allDone') }}</p>
    </div>
  </div>
</template>

<style>
.done {
  text-decoration: line-through;
  color: green;
}

.overdue {
  color: red;
}
</style>

<script>
import formatting from '@/mixins/formatting'

export default {
  name: 'ChoresChecklist',
  mixins: [formatting],
  data: () => ({
    chores: [],
    tasks: [],
    error: null
  }),

  beforeMount () {
    this.chores =
    {
      choresStartTime: new Date().setHours(8, 10, 0, 0),
      tasks: [
      ]
    }
    this.getChores()
  },

  methods: {
    actionsInactive (item) {
      console.log('item', item)
      return item.done || item.notDone
    },

    makeTime (value) {
      const timeString = '08:00:00'
      const [hours, minutes, seconds] = timeString.split(':')
      const date = new Date()
      date.setHours(hours, minutes, seconds, 0)
      return date
    },

    getChores () {
      fetch('/api/chores/checklist', {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then(response => {
          if (response.status === 200) {
            return response
          } else {
            throw new Error(response.statusText)
          }
        })
        .then(response => response.json())
        .then(data => {
          this.chores = data
          this.tasks = []
          this.chores.tasks.forEach(task => {
            this.tasks.push({
              id: task.chore.id,
              choreSubject: task.chore.choreSubject,
              done: task.done,
              notDone: task.notDone,
              dueTime: this.makeTime(task.dueTime)
            })
          })
        })
        .catch(error => {
          console.log(error)
        })
    },

    markAsDone (item) {
      fetch(`/api/chores/checklist/${item.id}/done`, {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then(response => {
          if (response.status === 200) {
            this.getChores()
          } else {
            throw new Error(response.statusText)
          }
        })
        .catch(error => {
          console.log(error)
        })
    },

    markAsNotDone (item) {
      fetch(`/api/chores/checklist/${item.id}/not-done`, {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then(response => {
          if (response.status === 200) {
            this.getChores()
          } else {
            throw new Error(response.statusText)
          }
        })
        .catch(error => {
          console.log(error)
        })
    },

    isOverdue (item) {
      const now = new Date()
      const dueTime = new Date(item.dueTime)
      dueTime.setFullYear(now.getFullYear(), now.getMonth(), now.getDate())
      return now > dueTime
    }
  }
}
</script>
