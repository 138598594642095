<template>
  <div>
    <v-card v-if="hasNotifications" class="notifications">
      <v-card-title>{{ $t('notifications.title') }}</v-card-title>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">{{ $t('notifications.table.subject') }} </th>
                <th class="text-left">{{ $t('notifications.table.message') }} </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in notifications" :key="index">
                <td>{{ item.subject }}</td>
                <td>{{ item.message }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <GrazingStillOpen />
  </div>
</template>

<script>
/*
      <ul>
        <li>Paddock changes</li>
        <li>Irrigation alerts</li>
        <li>Maintenance alerts</li>
        <li>Upcoming events and alerts</li>
        <li>Schedule</li>
        <li>Intra-team notifications</li>
      </ul>
*/

import i18n from '@/i18n'
import { formatDateTime } from '@/helpers'
import GrazingStillOpen from './grazing/GrazingStillOpen.vue'

export default {
  name: 'NotificationsComponent',
  components: { GrazingStillOpen },

  data: () => ({
    notifications: []
  }),
  computed: {
    hasNotifications () {
      return this.notifications != null && this.notifications.length > 0
    }
  },
  beforeMount () {
    this.findUrgentTasks()
    this.findBlockedTasks()
  },
  methods: {
    findOldestTaskDateTime (tasks) {
      let oldest
      tasks.forEach(task => {
        const taskDateTime = new Date(task.taskDateTime)
        if (oldest === undefined || taskDateTime < oldest) { oldest = taskDateTime }
      })
      return oldest
    },
    findBlockedTasks () {
      fetch('/api/tasks/filter?filter=blocked', {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then((response) => response.json())
        .then((data) => {
          if (data != null) {
            const oldestTaskDateTime = formatDateTime(this.findOldestTaskDateTime(data))
            const taskCount = data.length
            if (taskCount === 1) {
              this.notifications.push({
                subject: i18n.t('notifications.task.subject'),
                message: i18n.t('notifications.task.oneBlockedTask', { oldest: oldestTaskDateTime })
              })
            } else if (taskCount !== 0) {
              this.notifications.push({
                subject: i18n.t('notifications.task.subject'),
                message: i18n.t('notifications.task.blockedWithCount', { taskCount: taskCount, oldest: oldestTaskDateTime })
              })
            }
          }
        })
    },
    findUrgentTasks () {
      fetch('/api/tasks/filter?filter=urgent', {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then((response) => response.json())
        .then((data) => {
          if (data != null) {
            const oldestTaskDateTime = formatDateTime(this.findOldestTaskDateTime(data))
            const taskCount = data.length
            if (taskCount === 1) {
              this.notifications.push({
                subject: i18n.t('notifications.task.subject'),
                message: i18n.t('notifications.task.oneUrgentTask', { oldest: oldestTaskDateTime })
              })
            } else if (taskCount !== 0) {
              this.notifications.push({
                subject: i18n.t('notifications.task.subject'),
                message: i18n.t('notifications.task.urgentWithCount', { taskCount: taskCount, oldest: oldestTaskDateTime })
              })
            }
          }
        })
    }
  }
}
</script>

<style scoped>
.notifications {
  margin-top: 1em;
  margin-bottom: 1em;
  background-color: rgb(251, 227, 227);
}
</style>
