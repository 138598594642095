import i18n from '@/i18n'

export default {
  path: '/tasks',
  name: 'Tasks',
  meta: {
    title: i18n.t('mainMenu.tasks'),
    requiresAuth: true,
    role: ['granja-user', 'granja-admin']
  },
  component: () => import(/* webpackChunkName: "tasks" */ '@/views/Tasks.vue'),
  children: [
    {
      path: '',
      alias: 'overview',
      name: 'TasksOverview',
      meta: {
        title: 'Tasks Overview',
        requiresAuth: true,
        role: ['granja-user', 'granja-admin']
      },
      component: () => import(/* webpackChunkName: "tasks" */ '@/views/tasks/Overview.vue')
    },
    {
      path: 'regular',
      alias: 'regular',
      name: 'TasksRegular',
      meta: {
        title: 'Regular Tasks',
        requiresAuth: true,
        role: ['granja-user', 'granja-admin']
      },
      component: () => import(/* webpackChunkName: "tasks" */ '@/views/tasks/RegularTasks.vue')
    },
    {
      path: 'zones',
      name: 'TasksZone',
      meta: {
        title: 'Tasks per zone',
        requiresAuth: true,
        role: ['granja-user', 'granja-admin']
      },
      component: () => import(/* webpackChunkName: "tasks" */ '@/views/tasks/PerZone.vue')
    },
    {
      path: 'tools',
      name: 'TasksTool',
      meta: {
        title: 'Tasks per tool',
        requiresAuth: true,
        role: ['granja-user', 'granja-admin']
      },
      component: () => import(/* webpackChunkName: "tasks" */ '@/views/tasks/PerTool.vue')
    },
    {
      path: 'planned',
      name: 'TasksPlanned',
      meta: {
        title: 'Planned Tasks',
        requiresAuth: true,
        role: ['granja-user', 'granja-admin']
      },
      component: () => import(/* webpackChunkName: "tasks" */ '@/views/tasks/PlannedTasks.vue')
    },
    {
      path: 'details/:taskId',
      name: 'TaskDetails',
      meta: {
        title: 'Task Details',
        requiresAuth: true,
        role: ['granja-user', 'granja-admin']
      },
      component: () => import(/* webpackChunkName: "tasks" */ '@/views/tasks/TaskDetails.vue')
    },
    {
      path: 'per-zone/:zoneName',
      name: 'TasksPerZone',
      meta: {
        title: 'Tasks Per Zone',
        requiresAuth: true,
        role: ['granja-user', 'granja-admin']
      },
      component: () => import(/* webpackChunkName: "tasks" */ '@/views/tasks/TasksPerZone')
    }
  ]
}
