import i18n from '@/i18n'

export default {
  path: '/irrigation',
  name: 'Irrigation',
  meta: {
    title: i18n.t('mainMenu.irrigation'),
    requiresAuth: true,
    role: ['granja-user', 'granja-admin']
  },
  component: () => import(/* webpackChunkName: "irrigation" */ '@/views/Irrigation.vue'),
  children: [
    {
      path: 'overview',
      alias: '',
      name: 'IrrigationOverview',
      meta: {
        title: i18n.t('mainMenu.irrigation'),
        requiresAuth: true,
        role: ['granja-user', 'granja-admin']
      },
      component: () => import(/* webpackChunkName: "irrigation" */ '@/views/irrigation/Overview.vue')
    },
    {
      path: 'targets',
      name: 'IrrigationTargets',
      meta: {
        title: 'Irrigation Targets',
        requiresAuth: true,
        role: ['granja-user', 'granja-admin']
      },
      component: () => import(/* webpackChunkName: "irrigation" */ '@/views/irrigation/Targets.vue')
    },
    {
      path: 'sources',
      name: 'IrrigationSources',
      meta: {
        title: 'Irrigation Sources',
        requiresAuth: true,
        role: ['granja-user', 'granja-admin']
      },
      component: () => import(/* webpackChunkName: "irrigation" */ '@/views/irrigation/Sources.vue')
    },
    {
      path: 'history',
      name: 'IrrigationHistory',
      meta: {
        title: 'Irrigation History',
        requiresAuth: true,
        role: ['granja-user', 'granja-admin']
      },
      component: () => import(/* webpackChunkName: "irrigation" */ '@/views/irrigation/History.vue')
    }
  ]
}
