<template>
  <v-app>
    <v-app-bar v-if="!$keycloak.authenticated" app color="primary">
      <v-app-bar-nav-icon class="hidden-md-and-up" @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title v-if="$route.meta.title">
        <span style="color: white">{{ $route.meta.title }}</span>
        <span style="color: yellow"> - {{
          runtimeProfile
        }}</span>
      </v-toolbar-title>
      <v-toolbar-title v-if="!$route.meta.title">
        Granja EU <span style="color: yellow">- {{ runtimeProfile }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click="login" data-cy="login"> Login</v-btn>
      <v-btn v-if="hasFeature('signup')" color="secondary" @click="signUp" data-cy="signup"> Sign Up</v-btn>
    </v-app-bar>

    <v-app-bar v-if="$keycloak.authenticated" app color="primary">
      <v-app-bar-nav-icon class="hidden-md-and-up" @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title v-if="$route.meta.title">
        <span style="color: white">{{ $route.meta.title }}</span>
        <span style="color: yellow"> - {{
          runtimeProfile
        }}</span>
      </v-toolbar-title>
      <v-toolbar-title v-if="!$route.meta.title">
        Granja EU <span style="color: yellow">- {{ runtimeProfile }}</span>
      </v-toolbar-title>
    </v-app-bar>

    <v-navigation-drawer v-if="$keycloak.authenticated" app :permanent="$vuetify.breakpoint.mdAndUp" dark v-model="drawer"
      mobile-breakpoint="sm">
      <v-container>
        <v-row class="align-center justify-center mb-4">
          <v-sheet class="pa-4">
            <v-menu min-width="200px" rounded offset-y transition="slide-y-transition">
              <template v-slot:activator="{ on }">
                <v-btn data-cy="avatarIcon" icon x-large v-on="on">
                  <v-avatar color="primary" size="64" class="white--text headline">{{
                    $keycloak.fullName.split(" ")[0].toUpperCase().charAt(0) +
                    $keycloak.fullName.split(" ")[1].toUpperCase().charAt(0)
                  }}</v-avatar>
                </v-btn>
              </template>
              <v-card>
                <v-list-item-content class="justify-center">
                  <div class="mx-auto text-center">
                    <v-avatar color="primary" size="64" class="white--text headline">{{
                      $keycloak.fullName
                        .split(" ")[0]
                        .toUpperCase()
                        .charAt(0) +
                      $keycloak.fullName.split(" ")[1].toUpperCase().charAt(0)
                    }}</v-avatar>
                    <h3 class="pt-2">{{ $keycloak.fullName }}</h3>
                    <p class="caption mt-1">{{ $keycloak.userName }}</p>
                    <p class="pt-2" style="font-weight: bold">
                      Role: {{ $keycloak.realmAccess.roles }}
                    </p>
                    <v-divider class="my-3"></v-divider>
                    <v-btn depressed rounded text @click="$keycloak.accountManagement">
                      Edit Account
                    </v-btn>
                    <v-divider class="my-3"></v-divider>
                    <v-btn data-cy="logout" depressed rounded text @click="$keycloak.logoutFn">
                      Logout
                    </v-btn>
                  </div>
                </v-list-item-content>
              </v-card>
            </v-menu>
          </v-sheet>
        </v-row>
      </v-container>
      <v-divider></v-divider>
      <v-list>
        <v-list-item v-for="[icon, text, routerLink, dataCy] in menuItems" :key="icon" :to="routerLink" :data-cy="dataCy"
          link>
          <v-list-item-icon>
            <v-icon>{{ icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>
      <v-list>
        <v-list-item v-if="$keycloak.realmAccess.roles.includes('granja-admin')" :to="'/settings'" data-cy="settings"
          link>
          <v-list-item-icon>
            <v-icon>mdi-application-settings-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Settings</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon><v-icon>mdi-translate</v-icon></v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on">{{ $t('mainMenu.language') }}</v-btn>
                </template>
                <v-list>
                  <v-list-item @click="changeLanguage('en')">
                    <v-list-item-title>English</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="changeLanguage('es')">
                    <v-list-item-title>Español</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import userFeatures from '@/mixins/userFeatures'
import i18n from '@/i18n'

export default {
  name: 'App',

  components: {},
  mixins: [userFeatures],

  computed: {
    runtimeProfile () {
      return window.VUE_APP_PROFILE
    }
  },

  data: () => ({
    menuItems: [],
    drawer: false
  }),

  watch: {
    $route () {
      this.drawer = false
    }
  },

  async beforeMount () {
    const keycloak = await this.$keycloak

    if (keycloak.authenticated) {
      await this.loadUserFeatures()

      if (this.hasFeature('home')) {
        this.menuItems.push(['mdi-home', i18n.t('mainMenu.home'), '/home', 'drawerHome'])
      }
      if (this.hasFeature('dashboard')) {
        this.menuItems.push(['mdi-view-dashboard', i18n.t('mainMenu.dashboard'), '/dashboard', 'drawerDashboard'])
      }
      if (this.hasFeature('tasks')) {
        this.menuItems.push(['mdi-calendar-check-outline', i18n.t('mainMenu.tasks'), '/tasks', 'drawerTasks'])
      }
      if (this.hasFeature('grazing')) {
        this.menuItems.push(['mdi-account-cowboy-hat-outline', i18n.t('mainMenu.grazing'), '/grazing', 'drawerGrazing'])
      }
      if (this.hasFeature('crop')) {
        this.menuItems.push(['mdi-grass', i18n.t('mainMenu.crop'), '/crop', 'drawerCrop'])
      }
      if (this.hasFeature('strips')) {
        this.menuItems.push(['mdi-land-rows-horizontal', i18n.t('mainMenu.strips'), '/strips', 'drawerStrips'])
      }
      if (this.hasFeature('irrigation')) {
        this.menuItems.push(['mdi-water', i18n.t('mainMenu.irrigation'), '/irrigation', 'drawerIrrigation'])
      }
      if (this.hasFeature('livestock')) {
        this.menuItems.push(['mdi-cow', i18n.t('mainMenu.livestock'), '/livestock', 'drawerLivestock'])
      }
      if (this.hasFeature('butcher')) {
        this.menuItems.push(['mdi-knife', i18n.t('mainMenu.butcher'), '/butcher', 'drawerButcher'])
      }
      if (this.hasFeature('sales')) {
        this.menuItems.push(['mdi-cart-arrow-right', i18n.t('mainMenu.sales'), '/sales/inventory', 'drawerSales'])
      }
      if (this.hasFeature('shipping')) {
        this.menuItems.push(['mdi-truck-cargo-container', i18n.t('mainMenu.shipping'), '/shipping', 'drawerShipping'])
      }
      if (this.hasFeature('community')) {
        this.menuItems.push(['mdi-account-group-outline', i18n.t('mainMenu.community'), '/community', 'drawerTasks'])
      }
      if (this.hasFeature('customers')) {
        this.menuItems.push(['mdi-face-agent', i18n.t('mainMenu.customers'), '/customers', 'drawerCustomers'])
      }
      if (this.hasFeature('accounting')) {
        this.menuItems.push(['mdi-receipt', i18n.t('mainMenu.accounting'), '/accounting', 'drawerAccounting'])
      }
      if (this.hasFeature('timesheet')) {
        this.menuItems.push(['mdi-clipboard-text-clock', i18n.t('mainMenu.timesheet'), '/timesheet', 'drawerTimesheet'])
      }
    } else {
      console.error('User not authenticated')
    }
  },

  methods: {
    changeLanguage (lang) {
      i18n.locale = lang
    },

    login () {
      this.$router.push({ name: 'Home' })
    },

    signUp () {
      this.$router.push({ name: 'Signup' })
    }
  }
}
</script>

<style lang="scss">
@import "./scss/variables.scss";
</style>
