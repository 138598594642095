import i18n from '@/i18n'
import {
  formatDateTime as helperFormatDateTime,
  formatTime as helperFormatTime,
  formatDuration as helperFormatDuration,
  formatMoney as helperFormatMoney
} from '@/helpers'

export default {
  methods: {
    formatDateTime (value) {
      return helperFormatDateTime(value)
    },

    formatTime (value) {
      return helperFormatTime(value)
    },

    formatSex (sex) {
      if (sex) { return i18n.t('terms.' + sex.toLowerCase()) } else { return sex }
    },

    formatLifeStatus (status) {
      if (status) { return i18n.t('terms.' + status.toLowerCase()) } else { return status }
    },

    formatDate (value) {
      if (value) {
        const date = new Date(value)
        return new Intl.DateTimeFormat(navigator.language).format(date)
      } else { return value }
    },

    formattedAge (age) {
      if (age > 12) { return new Intl.NumberFormat(navigator.language, { maximumFractionDigits: 1, unit: 'year', unitDisplay: 'long', style: 'unit' }).format(age / 12) } else { return new Intl.NumberFormat(navigator.language, { maximumFractionDigits: 1, unit: 'month', unitDisplay: 'long', style: 'unit' }).format(age) }
    },

    formattedAgeInDays (age) {
      return new Intl.NumberFormat(navigator.language, { maximumFractionDigits: 1, unit: 'day', unitDisplay: 'long', style: 'unit' }).format(age)
    },

    formatDuration (duration) {
      return helperFormatDuration(duration)
    },

    formatMoney (value, currency) {
      return helperFormatMoney(value, currency)
    }

  }
}
