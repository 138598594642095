import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import es from 'vuetify/lib/locale/es'
import en from 'vuetify/lib/locale/en'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { en, es },
    current: navigator.language
  },
  theme: {
    themes: {
      light: {
        secondary: '#990066',
        primary: '#007A00',
        danger: '#F44336'
      }
    }
  }
})
