const localEnv = Symbol.for('local')
const stagingEnv = Symbol.for('staging')
const productionEnv = Symbol.for('production')
const adminRole = Symbol.for('granja-admin')

export const featureToggles = [
  {
    name: 'home',
    environments: [localEnv, stagingEnv, productionEnv]
  },
  {
    name: 'dashboard',
    featureNameKey: 'feature.dashboard',
    featureDescriptionKey: 'feature.descriptions.dashboard',
    environments: [localEnv, stagingEnv, productionEnv],
    userFeature: true
  },
  {
    name: 'tasks',
    featureNameKey: 'feature.tasks',
    featureDescriptionKey: 'feature.descriptions.tasks',
    environments: [localEnv, stagingEnv, productionEnv],
    userFeature: true
  },
  {
    name: 'grazing',
    featureNameKey: 'feature.grazing',
    environments: [localEnv, stagingEnv, productionEnv],
    userFeature: true
  },
  {
    name: 'crop',
    featureNameKey: 'feature.crop',
    environments: [localEnv, stagingEnv, productionEnv],
    userFeature: true
  },
  {
    name: 'strips',
    featureNameKey: 'feature.strips',
    environments: [localEnv, stagingEnv, productionEnv],
    userFeature: true
  },
  {
    name: 'irrigation',
    featureNameKey: 'feature.irrigation',
    environments: [localEnv, stagingEnv, productionEnv],
    userFeature: true
  },
  {
    name: 'livestock',
    featureNameKey: 'feature.livestock',
    environments: [localEnv, stagingEnv, productionEnv],
    userFeature: true
  },
  {
    name: 'butcher',
    featureNameKey: 'feature.butcher',
    environments: [localEnv, stagingEnv, productionEnv],
    userFeature: true
  },
  {
    name: 'sales',
    featureNameKey: 'feature.sales',
    environments: [localEnv, stagingEnv, productionEnv],
    userFeature: true
  },
  {
    name: 'shipping',
    featureNameKey: 'feature.shipping',
    environments: [localEnv, stagingEnv, productionEnv],
    userFeature: true
  },
  {
    name: 'customers',
    featureNameKey: 'feature.customers',
    environments: [localEnv, stagingEnv, productionEnv],
    userFeature: true
  },
  {
    name: 'accounting',
    featureNameKey: 'feature.accounting',
    environments: [localEnv, stagingEnv, productionEnv],
    userFeature: true
  },
  {
    name: 'timesheet',
    featureNameKey: 'feature.timesheet',
    environments: [localEnv, stagingEnv, productionEnv],
    userFeature: true
  },
  {
    name: 'zones',
    featureNameKey: 'feature.zones',
    environments: [localEnv, stagingEnv, productionEnv],
    userFeature: true,
    userRoles: [adminRole]
  },
  {
    name: 'chores',
    featureNameKey: 'feature.chores',
    environments: [localEnv, stagingEnv, productionEnv],
    userFeature: true
  },
  {
    name: 'signup',
    environments: [localEnv, stagingEnv]
  },
  {
    name: 'community',
    featureNameKey: 'feature.community',
    environments: [localEnv, stagingEnv, productionEnv],
    userFeature: true
  }
]

export function checkFeatureAvailability (feature, environment, userFeatures, userRoles, toggles) {
  const featureToggle = toggles.find(toggle => toggle.name === feature)

  if (featureToggle) {
    if (featureToggle.environments.includes(environment)) {
      let featureEnabled = true

      if (featureToggle.userRoles) {
        featureEnabled = userRoles.includes(featureToggle.userRoles)
      } else console.warn(`Feature toggle "${feature}" is not enabled for roles "${userRoles}"`)

      if (featureToggle.userFeature) {
        // console.log('userFeatures', userFeatures)

        featureEnabled = userFeatures.includes(featureToggle.name)
      } else console.warn(`Feature toggle "${feature}" is not enabled for user features "${userFeatures}"`)

      // console.log('featureEnabled', feature, featureEnabled, featureToggle.userFeature)
      return featureEnabled
    } else {
      console.warn(`Feature toggle "${feature}" is not enabled for environment "${environment.toString()}"`)
      return false
    }
  } else {
    console.warn(`Feature toggle "${feature}" not found`)
    return false
  }
}

export function isFeatureEnabled (feature, environment, userFeatures = [], userRoles = []) {
  return checkFeatureAvailability(feature, Symbol.for(environment), userFeatures, userRoles, featureToggles)
}
