import i18n from '@/i18n'

export default {
  path: '/accounting',
  name: 'Accounting',
  meta: {
    title: i18n.t('mainMenu.accounting'),
    requiresAuth: true,
    role: ['granja-user', 'granja-admin']
  },
  component: () => import(/* webpackChunkName: "accounting" */ '@/views/Accounting.vue'),
  children: [
    {
      path: '',
      alias: 'overview',
      name: 'AccountingOverview',
      meta: {
        title: i18n.t('mainMenu.accounting'),
        requiresAuth: true,
        role: ['granja-user', 'granja-admin']
      },
      component: () => import(/* webpackChunkName: "accounting" */ '@/views/accounting/Overview.vue')
    },
    {
      path: 'invoicing',
      name: 'AccountingInvoice',
      meta: {
        title: 'Invoice',
        requiresAuth: true,
        role: ['granja-user', 'granja-admin']
      },
      component: () => import(/* webpackChunkName: "accounting" */ '@/views/accounting/Invoicing.vue')
    },
    {
      path: 'accounts-payable',
      name: 'AccountingAccountsPayable',
      meta: {
        title: 'Accounts Payable',
        requiresAuth: true,
        role: ['granja-user', 'granja-admin']
      },
      component: () => import(/* webpackChunkName: "accounting" */ '@/views/accounting/AccountsPayable.vue')
    },
    {
      path: 'accounts-receivable',
      name: 'AccountingAccountsReceivable',
      meta: {
        title: 'Accounts Receivable',
        requiresAuth: true,
        role: ['granja-user', 'granja-admin']
      },
      component: () => import(/* webpackChunkName: "accounting" */ '@/views/accounting/AccountsReceivable.vue')
    },
    {
      path: 'cash-flow',
      name: 'AccountingCashFlow',
      meta: {
        title: 'Cash Flow',
        requiresAuth: true,
        role: ['granja-user', 'granja-admin']
      },
      component: () => import(/* webpackChunkName: "accounting" */ '@/views/accounting/CashFlow.vue')
    },
    {
      path: 'vendors',
      name: 'AccountingVendors',
      meta: {
        title: 'Vendors',
        requiresAuth: true,
        role: ['granja-user', 'granja-admin']
      },
      component: () => import(/* webpackChunkName: "accounting" */ '@/views/accounting/Vendors.vue')
    },
    {
      path: 'account/:accountId',
      name: 'AccountingAccount',
      meta: {
        title: 'Account',
        requiresAuth: true,
        role: ['granja-user', 'granja-admin']
      },
      component: () => import(/* webpackChunkName: "accounting" */ '@/views/accounting/Account.vue')
    },
    {
      path: 'bank',
      name: 'AccountingBank',
      meta: {
        title: 'Bank',
        requiresAuth: true,
        role: ['granja-user', 'granja-admin']
      },
      component: () => import(/* webpackChunkName: "accounting" */ '@/views/accounting/Bank.vue')
    },
    {
      path: 'bank/:accountId',
      name: 'AccountingBankAccount',
      meta: {
        title: 'Bank Account',
        requiresAuth: true,
        role: ['granja-user', 'granja-admin']
      },
      component: () => import(/* webpackChunkName: "accounting" */ '@/views/accounting/BankAccount.vue')
    },
    {
      path: 'invoice/record',
      name: 'accountingRecordInvoice',
      component: () => import(/* webpackChunkName: "accounting" */ '@/views/accounting/AccountingInvoiceRecord.vue')
    }
  ]
}
