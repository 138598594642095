import i18n from '@/i18n'

export default {
  path: '/crop',
  name: 'Crop',
  meta: {
    title: i18n.t('mainMenu.crop'),
    requiresAuth: true,
    role: ['granja-user', 'granja-admin']
  },
  component: () => import(/* webpackChunkName: "crop" */ '@/views/Crop.vue'),
  children: [
    {
      path: 'overview',
      alias: '',
      name: 'CropOverview',
      meta: {
        title: i18n.t('mainMenu.crop'),
        requiresAuth: true,
        role: ['granja-user', 'granja-admin']
      },
      component: () => import(/* webpackChunkName: "crop" */ '@/views/crop/Overview.vue')
    },
    {
      path: 'zone-report',
      name: 'CropFotoReports',
      meta: {
        title: 'Crop Zone Reports',
        requiresAuth: true,
        role: ['granja-user', 'granja-admin']
      },
      component: () => import(/* webpackChunkName: "crop" */ '@/views/crop/CropZoneReports.vue')
    },
    {
      path: 'crop-report',
      name: 'CropReports',
      meta: {
        title: 'Crop Reports',
        requiresAuth: true,
        role: ['granja-user', 'granja-admin']
      },
      component: () => import(/* webpackChunkName: "crop" */ '@/views/crop/CropReports.vue')
    }
  ]
}
