import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { messages } from './messages'

Vue.use(VueI18n)

const dateTimeFormats = {
  en: {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric'
    }
  },
  es: {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric'
    }
  }
}

export default new VueI18n({
  locale: navigator.language,
  fallbackLocale: 'en',
  dateTimeFormats,
  messages
})
