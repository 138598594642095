<template>
  <v-simple-table v-if="hasTasks">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">{{ $t('myGranja.tasks.table.taskNumber') }} </th>
          <th class="text-left">{{ $t('myGranja.tasks.table.taskSubject') }} </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in tasks" :key="item.taskId">
          <td><router-link :to="{ name: 'TaskDetails', params: { taskId: item.taskId } }">{{
            item.taskNumber
          }}</router-link></td>
          <td>{{ item.taskSubject }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import userFeatures from '@/mixins/userFeatures'

export default {
  name: 'PersonalTasks',
  mixins: [userFeatures],

  data: () => ({
    tasks: [],
    error: null
  }),

  computed: {
    hasTasks () {
      return this.tasks != null && this.tasks.length > 0
    }
  },
  beforeMount () {
    if (this.hasFeature('tasks')) { this.loadMyTasks() }
  },
  methods: {
    loadMyTasks () {
      fetch('/api/tasks/assigned-to-me', {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then((response) => {
          if (response.status !== 200) {
            this.error = response.statusText
          }
          return response
        })
        .then((response) => response.json())
        .then((data) => {
          this.tasks = data
        })
        .catch((error) => {
          this.error = error
        })
    }
  }
}
</script>
