import { isFeatureEnabled } from '@/featureToggles'
import { forceArray as helperForceArray } from '@/helpers'

export default {
  methods: {
    forceArray (value) {
      return helperForceArray(value)
    },

    async loadUserFeatures () {
      if (this.$keycloak.authenticated && this.$userFeatures.length === 0) {
        // console.log('loadUserFeatures Loading user features')
        try {
          const profile = await this.$keycloak.loadUserProfile()
          this.$userFeatures = this.forceArray(profile.attributes.tenant_features)

          // console.log('loadUserFeatures User features: ', this.$userFeatures)
          // console.log('loadUserFeatures User tenant_features: ', profile.attributes.tenant_features)
          // console.log('loadUserFeatures User tenant_id: ', profile.attributes.tenant_id)
        } catch (error) {
          console.error(error)
        }
      } else {
        console.warn('loadUserFeatures User not authenticated')
      }
    },

    hasFeature (feature) {
      const environment = window.VUE_APP_PROFILE

      if (this.$userFeatures.length === 0) {
        console.warn('User features not loaded yet')
        this.loadUserFeatures()
      }

      // console.log(`hasFeature(${feature})`, this.$userFeatures)

      if (this.$keycloak.authenticated) {
        const userRoles = this.$keycloak.realmAccess.roles

        return isFeatureEnabled(feature, environment, this.$userFeatures, userRoles)
      } else {
        return isFeatureEnabled(feature, environment, this.$userFeatures, [])
      }
    }

  }
}
