import i18n from '@/i18n'

export default {
  path: '/strips',
  name: 'StripsLanding',
  meta: {
    title: i18n.t('mainMenu.strips'),
    requiresAuth: true,
    role: ['granja-user', 'granja-admin']
  },
  component: () => import(/* webpackChunkName: "strips" */ '@/views/Strips.vue'),

  children: [
    {
      path: 'overview',
      alias: '',
      name: 'StripsOverview',
      meta: {
        title: i18n.t('mainMenu.strips'),
        requiresAuth: true,
        role: ['granja-user', 'granja-admin']
      },
      component: () => import(/* webpackChunkName: "strips" */ '@/views/strips/Overview.vue')
    },
    {
      path: 'list',
      name: 'StripsList',
      meta: {
        title: 'Strips List',
        requiresAuth: true,
        role: ['granja-user', 'granja-admin']
      },
      component: () => import(/* webpackChunkName: "strips" */ '@/views/strips/List.vue')
    },
    {
      path: 'actions',
      name: 'StripActions',
      meta: {
        title: 'Strip Actions',
        requiresAuth: true,
        role: ['granja-user', 'granja-admin']
      },
      component: () => import(/* webpackChunkName: "strips" */ '@/views/strips/StripActions.vue')
    },
    {
      path: 'actions/packages',
      name: 'StripActionPackages',
      meta: {
        title: 'Strip Action Packages',
        requiresAuth: true,
        role: ['granja-user', 'granja-admin']
      },
      component: () => import(/* webpackChunkName: "strips" */ '@/views/strips/ActionPackages.vue')
    },
    {
      path: ':id',
      name: 'StripDetails',
      meta: {
        title: 'Strip Details',
        requiresAuth: true,
        role: ['granja-user', 'granja-admin']
      },
      component: () => import(/* webpackChunkName: "strips" */ '@/views/strips/StripDetails.vue')
    }
  ]
}
