import i18n from '@/i18n'

export default {
  path: '/livestock',
  name: 'Livestock',
  meta: {
    title: i18n.t('mainMenu.livestock'),
    requiresAuth: true,
    role: ['granja-user', 'granja-admin']
  },
  component: () => import(/* webpackChunkName: "livestock" */ '@/views/Livestock.vue'),
  children: [
    {
      path: '',
      alias: 'overview',
      name: 'LifestockOverview',
      meta: {
        title: i18n.t('mainMenu.livestock'),
        requiresAuth: true,
        role: ['granja-user', 'granja-admin']
      },
      component: () => import(/* webpackChunkName: "livestock" */ '@/views/livestock/Overview.vue')
    },
    {
      path: 'inventory',
      alias: 'inventory',
      name: 'LifestockInventory',
      meta: {
        title: i18n.t('inventory.tab.inventory'),
        requiresAuth: true,
        role: ['granja-user', 'granja-admin']
      },
      component: () => import(/* webpackChunkName: "livestock" */ '@/views/livestock/Inventory.vue')
    },
    {
      path: 'breeding',
      name: 'LifestockBreeding',
      meta: {
        title: i18n.t('inventory.tab.breeding'),
        requiresAuth: true,
        role: ['granja-user', 'granja-admin']
      },
      component: () => import(/* webpackChunkName: "livestock" */ '@/views/livestock/Breeding.vue')
    },
    {
      path: 'potential-mothers',
      name: 'LifestockPotentialMothers',
      meta: {
        title: i18n.t('inventory.tab.potentialMothers'),
        requiresAuth: true,
        role: ['granja-user', 'granja-admin']
      },
      component: () => import(/* webpackChunkName: "livestock" */ '@/views/livestock/PotentialMothers.vue')
    },
    {
      path: 'animal-details/:animalId',
      name: 'AnimalDetails',
      meta: {
        title: 'Animal Details',
        requiresAuth: true,
        role: ['granja-user', 'granja-admin']
      },
      component: () => import(/* webpackChunkName: "livestock" */ '@/views/livestock/AnimalDetails.vue')
    }
  ]
}
