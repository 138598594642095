<template>
  <div>
    <div>Welcome</div>
    <Tools />
    <MyGranja />
    <Notifications />
  </div>
</template>

<script>
import MyGranja from './MyGranja.vue'
import Notifications from './Notifications.vue'
import Tools from './Tools.vue'

export default {
  name: 'HomeHome',
  components: { MyGranja, Notifications, Tools }
}
</script>
