import i18n from '@/i18n'

export default {
  path: '/butcher',
  name: 'Butcher',
  meta: {
    title: i18n.t('mainMenu.butcher'),
    requiresAuth: true,
    role: ['granja-user', 'granja-admin']
  },
  component: () => import('@/views/Butcher.vue'),
  children: [
    {
      path: '',
      name: 'ButcherOverview',
      meta: {
        title: i18n.t('mainMenu.butcher'),
        requiresAuth: true,
        role: ['granja-user', 'granja-admin']
      },
      component: () => import('@/views/butcher/Overview.vue')
    },
    {
      path: 'inventory',
      name: 'ButcherInventory',
      meta: {
        title: 'Butcher Inventory',
        requiresAuth: true,
        role: ['granja-user', 'granja-admin']
      },
      component: () => import('@/views/butcher/Inventory.vue')
    },
    {
      path: 'pieces',
      name: 'ButcherPieces',
      meta: {
        title: 'Butcher Pieces',
        requiresAuth: true,
        role: ['granja-user', 'granja-admin']
      },
      component: () => import('@/views/butcher/Pieces.vue')
    },
    {
      path: 'slaughter',
      name: 'ButcherSlaughter',
      meta: {
        title: 'Butcher Slaughter',
        requiresAuth: true,
        role: ['granja-user', 'granja-admin']
      },
      component: () => import('@/views/butcher/Slaughter.vue')
    },
    {
      path: 'carcass-report',
      name: 'ButcherCarcassReport',
      meta: {
        title: 'Butcher Carcass Report',
        requiresAuth: true,
        role: ['granja-user', 'granja-admin']
      },
      component: () => import('@/views/butcher/CarcassReport.vue')
    },
    {
      path: 'bill-of-lading',
      name: 'ButcherBillOfLading',
      meta: {
        title: 'Butcher Bill Of Lading',
        requiresAuth: true,
        role: ['granja-user', 'granja-admin']
      },
      component: () => import('@/views/butcher/BillOfLading.vue')
    }
  ]
}
