import i18n from '@/i18n'

export default {
  path: '/sales/',
  name: 'Sales',
  component: () => import(/* webpackChunkName: "sales" */ '@/views/Sales.vue'),
  children: [
    {
      path: '',
      alias: 'inventory',
      name: 'SalesInventory',
      meta: {
        title: i18n.t('mainMenu.sales'),
        requiresAuth: true,
        role: ['granja-user', 'granja-admin']
      },
      component: () => import(/* webpackChunkName: "sales" */ '@/views/sales/Inventory.vue')
    },
    {
      path: 'records',
      name: 'SalesRecords',
      meta: {
        title: 'Sales Records',
        requiresAuth: true,
        role: ['granja-user', 'granja-admin']
      },
      component: () => import(/* webpackChunkName: "sales" */ '@/views/sales/SalesRecords.vue')
    },
    {
      path: 'carts',
      name: 'SalesCarts',
      meta: {
        title: 'Sales Carts',
        requiresAuth: true,
        role: ['granja-user', 'granja-admin']
      },
      component: () => import(/* webpackChunkName: "sales" */ '@/views/sales/ShoppingCarts.vue')
    }
  ]
}
