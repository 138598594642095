import i18n from '@/i18n'

export default {
  path: '/grazing',
  name: 'Grazing',
  meta: {
    title: i18n.t('mainMenu.grazing'),
    requiresAuth: true,
    role: ['granja-user', 'granja-admin']
  },
  component: () => import(/* webpackChunkName: "grazing" */ '@/views/Grazing.vue'),
  children: [
    {
      path: '',
      name: 'GrazingOverview',
      meta: {
        title: i18n.t('mainMenu.grazing'),
        requiresAuth: true,
        role: ['granja-user', 'granja-admin']
      },
      component: () => import(/* webpackChunkName: "grazing" */ '@/views/grazing/Overview.vue')
    },
    {
      path: 'access',
      name: 'GrazingAccess',
      meta: {
        title: 'Grazing Access',
        requiresAuth: true,
        role: ['granja-user', 'granja-admin']
      },
      component: () => import(/* webpackChunkName: "grazing" */ '@/views/grazing/Access.vue')
    },
    {
      path: 'report',
      name: 'GrazingReport',
      meta: {
        title: 'Grazing Report',
        requiresAuth: true,
        role: ['granja-user', 'granja-admin']
      },
      component: () => import(/* webpackChunkName: "grazing" */ '@/views/grazing/GrazingReport.vue')
    }
  ]
}
