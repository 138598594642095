<template>
  <v-dialog v-model="showDialog">
    <v-form @submit.prevent="report()">
      <v-card>
        <v-card-title>{{ $t('fotoReport.title') }}</v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-select :items="zonesAvailable" :label="$t('fotoReport.selectZone')"
                v-model="fotoReport.reportedZoneId"></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-file-input :label="$t('fotoReport.selectPicture')" filled prepend-icon="mdi-camera"
                v-model="fotoReport.reportedImage"></v-file-input>
              {{ $t('terms.upload') }} {{ uploadPercentCompleted }} %
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="secondary" text type="cancel">{{ $t('terms.cancel') }}</v-btn>
          <v-btn color="primary" type="submit">{{ $t('fotoReport.saveReport') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  name: 'FotoReport',
  props: {
    show: Boolean
  },

  data: () => ({
    fotoReport: {},
    uploadPercentCompleted: 0,
    zonesAvailable: []
  }),

  computed: {
    showDialog: {
      get () {
        return this.show
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },

  beforeMount () {
    this.loadZones()
  },

  methods: {
    loadZones () {
      fetch('/api/zones', {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.zonesAvailable = []
          data.zones.forEach((zone) => {
            this.zonesAvailable.push({
              text: zone.zoneName,
              value: zone.id
            })
          })
          this.zonesAvailable = this.zonesAvailable.sort((a, b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0))
        })
    },

    report () {
      const formData = new FormData()
      formData.append('file', this.fotoReport.reportedImage)

      const request = new XMLHttpRequest()
      request.open('POST', `/api/zones/${this.fotoReport.reportedZoneId}/upload-picture`)
      request.setRequestHeader('Authorization', 'Bearer ' + `${this.$keycloak.token}`)

      request.upload.addEventListener('progress', (e) => {
        const percentCompleted = (e.loaded / e.total) * 100
        this.uploadPercentCompleted = Math.ceil(percentCompleted)
      })

      request.addEventListener('load', (e) => {
        const status = e.currentTarget.status
        const statusText = e.currentTarget.statusText

        if (status === 200) {
          this.showDialog = false
          this.uploadPercentCompleted = 0
          this.fotoReport = {}
        } else {
          this.error = statusText
        }
      })

      request.send(formData)

      // fetch(`/api/zones/${this.fotoReport.reportedZoneId}/upload-picture`, {
      //   method: 'POST',
      //   headers: {
      //     'Authorization': 'Bearer ' + `${this.$keycloak.token}`,
      //   },
      //   body: formData
      // })
      //   .then(() => {
      //     this.showDialog = false
      //     this.fotoReport = {}
      //   })
    }
  }

}
</script>
