<template>
  <div>
    <v-toolbar>
      <v-toolbar-title>{{ $t('terms.tools') }}</v-toolbar-title>
      <v-toolbar-items>
        <v-btn icon @click="pictureForZone()">
          <v-icon>mdi-camera</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <FotoReport :show="showFotoReportDialog" @input="showFotoReportDialog = $event" />
  </div>
</template>

<script>
import FotoReport from './FotoReport.vue'

export default {
  name: 'ToolsComponent',
  components: { FotoReport },

  data: () => ({
    showFotoReportDialog: false
  }),

  methods: {
    pictureForZone () {
      this.showFotoReportDialog = true
    }
  }
}
</script>
