import i18n from '@/i18n'

export default {
  path: '/settings',
  name: 'SettingsLanding',
  meta: {
    title: i18n.t('mainMenu.settings'),
    requiresAuth: true,
    role: ['granja-admin']
  },
  component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings'),
  children: [
    {
      path: 'overview',
      alias: '',
      name: 'SettingsOverview',
      meta: {
        title: i18n.t('settings.tab.overview'),
        requiresAuth: true,
        role: ['granja-admin']
      },
      component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/Overview')
    },
    {
      path: 'zones',
      name: 'SettingsZones',
      meta: {
        title: i18n.t('settings.tab.zones'),
        requiresAuth: true,
        role: ['granja-admin']
      },
      component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/Zones')
    },
    {
      path: 'chores',
      name: 'SettingsChores',
      meta: {
        title: i18n.t('settings.tab.chores'),
        requiresAuth: true,
        role: ['granja-admin']
      },
      component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/Chores')
    },
    {
      path: 'account',
      name: 'SettingsAccount',
      meta: {
        title: i18n.t('settings.tab.account'),
        requiresAuth: true,
        role: ['granja-admin']
      },
      component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/Account')
    },
    {
      path: 'users',
      name: 'SettingsUsers',
      meta: {
        title: i18n.t('settings.tab.users'),
        requiresAuth: true,
        role: ['granja-admin']
      },
      component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/AdminUsers')
    }
  ]
}
