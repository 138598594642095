import Vue from 'vue'
import i18n from './i18n'

export function userAuthorized (toRoute) {
  const allowed = toRoute.meta.role.some(role => {
    return Vue.prototype.$keycloak.realmAccess.roles.includes(role)
  })
  return allowed
}

export function formatDate (value) {
  if (value) {
    const dateTime = new Date(value)
    const today = new Date()

    const diffTime = Math.abs(today - dateTime)
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

    if (diffDays > 7) {
      return Intl.DateTimeFormat(navigator.language, {
        year: 'numeric', month: 'short', day: 'numeric'
      }).format(dateTime)
    } else if (diffDays > 2) {
      return Intl.DateTimeFormat(navigator.language, {
        weekday: 'long'
      }).format(dateTime)
    } else if (diffDays === 2) { return i18n.t('terms.yesterday') } else { return i18n.t('terms.today') }
  } else { return '' }
}

export function formatTime (value) {
  if (value) {
    const dateTime = new Date(value)

    return Intl.DateTimeFormat(navigator.language, {
      hour: 'numeric', minute: 'numeric', hour12: false
    }).format(dateTime)
  } else { return '' }
}

export function formatDateTime (value, today = new Date()) {
  if (value) {
    const dateTime = new Date(value)

    if (isNaN(dateTime.getTime())) {
      return ''
    }

    const diffTime = Math.abs(today - dateTime)
    const diffHours = Math.floor(diffTime / (1000 * 60 * 60))
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24))

    if (diffDays > 7) {
      return Intl.DateTimeFormat(navigator.language, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false
      }).format(dateTime)
    } else if (diffDays >= 2) {
      if (dateTime > today) {
        return i18n.t('terms.upcoming') +
          ' ' +
          Intl.DateTimeFormat(navigator.language, {
            weekday: 'long',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: false
          }).format(dateTime)
      } else {
        return Intl.DateTimeFormat(navigator.language, {
          weekday: 'long',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          hour12: false
        }).format(dateTime)
      }
    } else if (diffHours >= 24) {
      if (dateTime > today) {
        return i18n.t('terms.tomorrow') +
          ', ' +
          Intl.DateTimeFormat(navigator.language, {
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: false
          }).format(dateTime)
      } else {
        return i18n.t('terms.yesterday') +
          ', ' +
          Intl.DateTimeFormat(navigator.language, {
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: false
          }).format(dateTime)
      }
    } else {
      const todayDateOnly = new Date(today)
      todayDateOnly.setHours(0, 0, 0, 0)
      const dateTimeDateOnly = new Date(dateTime)
      dateTimeDateOnly.setHours(0, 0, 0, 0)

      if (dateTimeDateOnly.valueOf() === todayDateOnly.valueOf()) {
        return i18n.t('terms.today') +
          ', ' +
          Intl.DateTimeFormat(navigator.language, {
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: false
          }).format(dateTime)
      } else if (dateTime > today) {
        return i18n.t('terms.laterToday') +
          ', ' +
          Intl.DateTimeFormat(navigator.language, {
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: false
          }).format(dateTime)
      } else {
        return i18n.t('terms.yesterday') +
          ', ' +
          Intl.DateTimeFormat(navigator.language, {
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: false
          }).format(dateTime)
      }
    }
  } else {
    return ''
  }
}

function parseDurationString (durationString) {
  const durationRegex = /PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?/

  const match = durationRegex.exec(durationString)
  if (!match) {
    throw new Error('Invalid duration string')
  }

  const hours = match[1] ? parseInt(match[1], 10) : 0
  const minutes = match[2] ? parseInt(match[2], 10) : 0
  const seconds = match[3] ? parseInt(match[3], 10) : 0

  const durationInSeconds = hours * 3600 + minutes * 60 + seconds
  return durationInSeconds
}

export function formatDuration (duration) {
  if (!duration) return ''

  if (isNaN(duration)) {
    return formatDuration(parseDurationString(duration))
  } else {
    const days = Math.floor(duration / 86400)
    const hours = Math.floor((duration % 86400) / 3600)
    const minutes = Math.floor((duration % 3600) / 60)
    const seconds = Math.round(duration % 60)

    const daysStr = days > 0 ? `${days}d ` : ''
    const hoursStr = hours > 0 ? `${hours}h ` : ''
    const minutesStr = minutes > 0 ? `${minutes}m ` : ''
    const secondsStr = seconds > 0 ? `${seconds}s` : ''

    return `${daysStr}${hoursStr}${minutesStr}${secondsStr}`
  }
}

export function calculateAverageDuration (sessions) {
  if (sessions === null || sessions === undefined) { return '' }

  if (sessions.length === 0) { return '' }

  let numberOfSessions = sessions.length

  if (sessions.length > 0) {
    const totalDuration = sessions.reduce((total, session) => {
      if (session.accessClosed === null) {
        numberOfSessions--
        return total
      }

      const opened = new Date(session.accessOpened)
      const closed = new Date(session.accessClosed)
      const duration = closed.getTime() - opened.getTime()
      return total + duration
    }, 0)

    if (totalDuration > 0) {
      const averageDuration = totalDuration / numberOfSessions
      return formatDuration(averageDuration / 1000)
    } else return ''
  }
  return ''
}

export function formatMoney (value, currency = 'EUR') {
  if (value === null || value === undefined || value === '') { return '' }

  return new Intl.NumberFormat(navigator.language, {
    style: 'currency',
    currency,
    useGrouping: true
  }).format(value)
}

export function forceArray (value) {
  if (value === null || value === undefined || value === '') { return [] }
  if (Array.isArray(value)) {
    return value.reduce((acc, val) => {
      acc.push(...val.split(',').map((item) => item.trim()))
      return acc
    }, [])
  } else {
    return value.split(',').map((item) => item.trim())
  }
}
