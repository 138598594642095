import i18n from '@/i18n'

export default {
  path: '/zones',
  name: 'Zones',
  meta: {
    title: i18n.t('mainMenu.zones'),
    requiresAuth: true,
    role: ['granja-user', 'granja-admin']
  },
  component: () => import(/* webpackChunkName: "zones" */ '@/views/settings/Zones.vue'),
  children: [
    {
      path: '/zones/:zoneId/report',
      name: 'ZoneReport',
      meta: {
        title: 'Report for Zone',
        requiresAuth: true,
        role: ['granja-user', 'granja-admin']
      },
      component: () => import('@/views/crop/ZoneReport.vue')
    }
  ]
}
