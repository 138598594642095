export const messages = {
  en: {
    terms: {
      today: 'Today',
      laterToday: 'Later today',
      tomorrow: 'Tomorrow',
      yesterday: 'Yesterday',
      upcoming: 'Upcoming',
      accountsPayable: 'Accounts Payable',
      recordInvoice: 'Record Invoice',
      payInvoice: 'Pay Invoice',
      male: 'Male',
      female: 'Female',
      alive: 'Alive',
      sick: 'Sick',
      dead: 'Dead',
      close: 'Close',
      save: 'Save',
      cancel: 'Cancel',
      ok: 'OK',
      tools: 'Tools',
      edit: 'Edit',
      delete: 'Delete',
      upload: 'Upload',
      reopen: 'Reopen',
      confirm: 'Confirm',
      danger: 'Danger',
      tableHeaders: {
        when: 'When',
        subject: 'Subject',
        message: 'Message',
        actions: 'Actions',
        name: 'Name',
        next: 'Next',
        state: 'State',
        opened: 'Opened',
        closed: 'Closed',
        species: 'Type',
        breed: 'Breed',
        birth: 'Birth',
        sex: 'Sex',
        femaleParent: 'Female Parent',
        age: 'Age',
        potentiallyPregnant: 'Pregnant?',
        earMark: 'Ear Mark',
        lifeStatus: 'Life Status',
        usageType: 'Usage Type'
      },
      months: {
        january: 'January',
        february: 'February',
        march: 'March',
        april: 'April',
        may: 'May',
        june: 'June',
        july: 'July',
        august: 'August',
        september: 'September',
        october: 'October',
        november: 'November',
        december: 'December'
      },
      animalTypes: {
        PIG: 'Pig(s)',
        CATTLE: 'Cattle'
      }
    },
    generic: {
      deleteConfirmationDialog: {
        title: 'Confirm you want to delete this item',
        text: 'This action cannot be undone. Choose wisely.',
        item: 'Item'
      }
    },
    feature: {
      dashboard: 'Dashboard',
      tasks: 'Tasks',
      grazing: 'Grazing',
      crop: 'Crop',
      irrigation: 'Irrigation',
      livestock: 'Livestock',
      butcher: 'Butcher',
      sales: 'Shop',
      strips: 'Forest strips',
      shipping: 'Shipping',
      zones: 'Zones',
      customers: 'Customers',
      accounting: 'Accounting',
      timesheet: 'Timesheet',
      chores: 'Chores',
      community: 'Community',
      descriptions: {
        dashboard: 'The dashboard gives you a quick overview of current situation of your farm.',
        tasks: 'Tasks are the things that need to be done on your farm.'
      }
    },
    mainMenu: {
      home: 'Home',
      shipping: 'Shipping',
      tasks: 'Tasks',
      grazing: 'Grazing',
      crop: 'Crop',
      irrigation: 'Irrigation',
      livestock: 'Livestock',
      butcher: 'Butcher',
      sales: 'Shop',
      timesheet: 'Timesheet',
      accounting: 'Accounting',
      zones: 'Zones',
      customers: 'Customers',
      language: 'Language',
      strips: 'Strips',
      dashboard: 'Dashboard',
      community: 'Community'
    },
    accountingHome: {
      paymentsToSuppliers: 'Payments to suppliers'
    },
    access: {
      title: 'Access to Zones',
      OPEN: 'Open',
      CLOSED: 'Closed'
    },
    livestock: {
      overview: {
        lifeStatus: {
          title: 'Life Status',
          aliveLineOne: '{aliveCount} {type}',
          aliveLineMany: '{aliveCount} {type}',
          sickAppendixOne: ' and {sickCount} is sick',
          sickAppendixMany: ' and {sickCount} are sick'
        },
        animalsOnSale: {
          title: 'Animals on Sale'
        },
        upcomingEvents: {
          title: 'Upcoming Events'
        }
      }
    },
    inventory: {
      search: 'Search all fields',
      addAnimal: 'Add Animal',
      markReadyForSale: 'Sale',
      sex: {
        FEMALE: 'Female',
        MALE: 'Male'
      },
      tab: {
        overview: 'Situation Report',
        inventory: 'Inventory',
        breeding: 'Breeding',
        potentialMothers: 'Potential Mothers'
      },
      addAnimalDialog: {
        externalId: 'External ID',
        type: 'Type',
        breed: 'Breed',
        femaleParentId: 'Female parent ID',
        dateOfBirth: 'Date of birth',
        sex: 'Sex',
        cancel: 'Cancel',
        save: 'Save'
      },
      deleteAnimalDialog: {
        title: 'Are you sure you want to delete this animal?',
        cancel: 'Cancel',
        ok: 'OK'
      },
      sickDialog: {
        title: 'Are you sure you want to mark this animal as sick?',
        comment: 'Comment',
        cancel: 'Cancel',
        save: 'Save'
      }
    },
    breeding: {
      motherId: 'Mother ID',
      daysAfterBirth: 'Days after birth',
      offspringId: 'Offspring ID',
      offspringBirth: 'Offspring Birth',
      offspringAge: 'Offspring Age',
      lastOffspringInMonths: 'After last offspring',
      offsprings: 'Offsprings'
    },
    customers: {
      search: 'Search all fields'
    },
    tasks: {
      search: 'Search all content',
      created: 'Created',
      assignedTo: 'Assigned to',
      latestComment: 'Latest comment',
      add: 'Add',
      showDone: 'Done',
      showOpen: 'Open',
      planned: 'Planned for',
      toolsNeeded: 'Tools needed',
      externalURL: 'External URL for more details:',
      tab: {
        overview: 'Overview',
        tasks: 'Tasks',
        regular: 'Regular',
        zones: 'Zones',
        tools: 'Tools',
        planned: 'Planned'
      },
      actions: {
        done: 'Done',
        block: 'Block',
        unblock: 'Unblock',
        assign: 'Assign',
        unassign: 'Unassign',
        comment: 'Comment',
        urgent: 'Urgent',
        resetUrgent: 'Regular',
        plan: 'Plan',
        edit: 'Edit'
      },
      filter: {
        urgent: 'Urgent',
        blocked: 'Blocked'
      },
      overview: {
        report: 'There are {openTasks} open tasks of which {urgentTasks} are urgent and {blockedTasks} are blocked.'
      },
      tasksPerZone: {
        title: 'Tasks for Zone {zoneName}'
      }
    },
    addTaskDialog: {
      title: 'Add Task',
      taskSubject: 'Subject',
      taskDescription: 'Details about the task',
      taskUrgent: 'Is urgent?',
      subjectRequired: 'Subject is required',
      zoneName: 'Zone',
      toolsNeeded: 'Tools needed',
      externalURL: 'External URL for more details',
      budget: 'Budget'
    },
    confirmMarkDoneDialog: {
      title: 'Confirm mark task as done',
      markAsDone: 'Mark as done'
    },
    blockTaskDialog: {
      title: 'Block this task?',
      unblockTitle: 'Unblock this task?',
      reason: 'What is the reason?'
    },
    commentDialog: {
      title: 'Comment on a task',
      comment: 'Enter the text for the comment'
    },
    home: {
      eventHistory: 'Event History'
    },
    notifications: {
      title: 'Notifications',
      task: {
        subject: 'Tasks',
        oneUrgentTask: 'There is one urgent task since {oldest}',
        urgentWithCount: 'There are {taskCount} urgent tasks since {oldest}',
        oneBlockedTask: 'There is one blocked task since {oldest}',
        blockedWithCount: 'There are {taskCount} blocked tasks since {oldest}'
      },
      table: {
        subject: 'Subject',
        message: 'Message'
      }
    },
    myGranja: {
      title: 'My Granja',
      tasks: {
        table: {
          taskNumber: 'Task Number',
          taskSubject: 'Subject'
        }
      }
    },
    fotoReport: {
      title: 'Foto Report',
      selectZone: 'Select Zone',
      selectPicture: 'Picture',
      saveReport: 'Report'
    },
    confirmDeleteDialog: {
      title: 'Confirm you want to delete this task',
      delete: 'Delete Task'
    },
    reopenTaskDialog: {
      title: 'Reopen task',
      reason: 'Reason for reopening'
    },
    confirmImageDeleteDialog: {
      title: 'Do you want to delete this image?'
    },
    confirmDeleteImageFromZoneDialog: {
      title: 'Do you want to delete this image?'
    },
    planTaskDialog: {
      title: 'Plan this task',
      plannedForMonth: 'Planned for month',
      plannedForYear: 'Planned for year'
    },
    plannedTasks: {
      tasksPlannedFor: 'Tasks planned for '
    },
    animalDetails: {
      title: 'Animal Profile',
      EarTag: 'Ear Mark',
      animalType: 'Animal Type',
      animalBreed: 'Animal Breed',
      lifeStatus: 'Life Status',
      sex: 'Sex',
      dateOfBirth: 'Date of Birth',
      femaleParentId: 'Female Parent ID',
      statusIndicators: 'Status Indicators',
      observations: 'Observations',
      medicalHistory: 'Medical History',
      salesZone: 'Sales',
      dtOfferForSale: 'Offer this animal for sale',
      ddForSale: 'This animal is for sale since {forSaleSince}.',
      ddOfferForSale: 'This animal can be offered for sale.',
      btnStopSelling: 'Stop Selling',
      btnStartSelling: 'Start Selling',
      dangerZone: 'Danger Zone',
      dtDeleteRecord: 'Delete this record',
      ddDeleteRecord: 'Deleting this record cannot be undone. Choose wisely.',
      dtEditAnimal: 'Edit animal data',
      ddEditAnimal: 'Some information about this animal can be edited.',
      btnEditAnimal: 'Edit animal data',
      markAsSick: 'Mark as Sick',
      markAsHealthy: 'Mark as Healthy',
      comment: 'Enter comment',
      maintenance: {
        title: 'Maintenance',
        dtMissingEarTag: 'Order missing ear tags',
        ddMissingEarTag: 'Missing ear tags can be ordered for this animal.',
        ddMissingEarTagOrdered: 'Replacement ear tags have been ordered on {dateOrdered}',
        btnMissingEarTag: 'Order Ear Tags',
        btnEarTagsPutOn: 'Ear Tags Put On',
        dtEarTagPutOn: 'Put on ear tags',
        ddEarTagPutOn: 'Put on ear tags after delivery of them has been taken.'
      }
    },
    orderEarTagsDialog: {
      title: 'Order Ear Tags',
      leftEar: 'Left ear',
      rightEar: 'Right ear'
    },
    putOnEarTagsDialog: {
      title: 'Put on ear tags'
    },
    crop: {
      tab: {
        overview: 'Overview',
        zoneReport: 'Zone Report',
        cropReport: 'Crop Report'
      }
    },
    grassReport: {
      description: 'Verify that the bite mark on the grass cannot be seen anymore',
      tableHeaders: {
        name: 'Name',
        usage: 'Usage',
        seeded: 'Seeded',
        germinated: 'Germinated',
        waterNeeded: 'Water Needed',
        grazing: 'Grazing',
        manureVisible: 'Manure Visible',
        flowers: 'Flowers',
        mushrooms: 'Mushrooms',
        cropReadyForHarvest: 'Harvest Ready',
        biteMark: 'Bite Mark'
      }
    },
    grazing: {
      tab: {
        overview: 'Overview',
        access: 'Access',
        report: 'Report'
      }
    },
    grazingProposals: {
      title: 'Grazing Proposals',
      description: 'The following zones might be suitable for grazing today.',
      tableHeaders: {
        name: 'Zone Name',
        actions: 'Actions',
        size: 'Zone Size',
        accessClosed: 'Access Closed',
        details: 'Details'
      },
      btnShowAll: 'Show All',
      springRecommendation: 'We are in spring time now. Consider to do a quick pass across all paddocks to graze off the tip of the grass.'
    },
    offspringSection: {
      btnAddOffspring: 'Add Offspring'
    },
    grazingReport: {
      title: 'Grazing Report',
      zone: 'Zone',
      quality: 'Quality',
      state: 'State',
      opened: 'Opened',
      closed: 'Closed',
      averageSessionDuration: 'Average Duration'
    },
    cropAlerts: {
      title: 'Crop Alerts',
      waterAlerts: 'Water Alerts'
    },
    grazingStillOpen: {
      title: 'Zones Still Open for Grazing',
      zoneName: 'Zone Name',
      openDuration: 'Open Duration'
    },
    butcher: {
      tab: {
        overview: 'Overview',
        inventory: 'Inventory',
        pieces: 'Pieces',
        slaughter: 'Slaughter',
        billOfLading: 'Bill of Lading',
        carcassReport: 'Carcass Report'
      },
      slaughter: {
        shipToSlaughterHouse: 'Ship to Slaughter House'
      }
    },
    zoneAccessIcon: {
      titleOpen: 'Open Zone',
      titleClose: 'Close Zone',
      date: 'Date',
      time: 'Time'
    },
    sendToSlaughterDialog: {
      title: 'Send animals to slaugther',
      prompt: 'Do you want to send the selected animals to slaugther?'
    },
    accounting: {
      tab: {
        overview: 'Overview',
        invoicing: 'Invoicing',
        accountsPayable: 'Accounts Payable',
        accountsReceivable: 'Accounts Receivable',
        cashFlow: 'Cash Flow',
        vendors: 'Vendors',
        bank: 'Bank'
      }
    },
    irrigation: {
      tab: {
        overview: 'Overview',
        targets: 'Targets',
        sources: 'Sources',
        history: 'History'
      },
      tableHeaders: {
        time: 'Time',
        zone: 'Zone',
        object: 'Object',
        irrigationType: 'Irrigation Type',
        duration: 'Duration',
        status: 'Status',
        actualDuration: 'Actual Duration'
      }
    },
    irrigationTargets: {
      title: 'Irrigation Targets',
      add: 'Add Irrigation Target',
      tableHeaders: {
        zone: 'Zone',
        name: 'Name',
        duration: 'Duration',
        daysBetween: 'Days Between',
        irrigationType: 'Irrigation Type',
        source: 'Source',
        weight: 'Weight'
      }
    },
    irrigationSources: {
      title: 'Irrigation Sources',
      add: 'Add Irrigation Source',
      tableHeaders: {
        name: 'Name',
        startTime: 'Start Time',
        endTime: 'End Time'
      }
    },
    strips: {
      tab: {
        overview: 'Overview',
        stripsList: 'Strips',
        stripActions: 'Actions',
        stripActionPackages: 'Packages'
      },
      overview: {
        toolbar: {
          add: 'Add Strip'
        }
      },
      list: {
        details: 'Details',
        zone: 'Zone',
        designator: 'Designator',
        description: 'Description',
        size: 'Size',
        irrigated: 'Irrigated',
        complete: 'Complete',
        nextStep: 'Next Step',
        actions: 'Actions'
      },
      addDialog: {
        title: 'Add Strip',
        description: 'Description',
        size: 'Size in m2',
        irrigated: 'Irrigated',
        selectZone: 'Select Zone'
      },
      actions: {
        toolbar: {
          add: 'Add Action',
          package: 'Packages'
        },
        tableHeaders: {
          description: 'Description',
          actions: 'Actions',
          blockTime: 'Block Time (days)',
          earliestMonth: 'Earliest Month'
        }
      },
      addActionDialog: {
        title: 'Add Action',
        description: 'Description',
        blockTime: 'Block Time (days)'
      },
      editActionDialog: {
        title: 'Edit Action'
      },
      details: {
        title: 'Strip Profile',
        description: 'Description',
        size: 'Size in m2',
        irrigated: 'Irrigated',
        actions: 'Actions',
        nextAction: 'Next Action',
        percentComplete: 'Percent Complete'
      },
      actionPackages: {
        toolbar: {
          add: 'Add Package'
        }
      },
      addActionPackageDialog: {
        title: 'Add Action Package',
        packageName: 'Package Name'
      }
    },
    chores: {
      allDone: 'All chores are done for today',
      tableHeaders: {
        choreDueTime: 'Due Time',
        choreSubject: 'Subject'
      }
    },
    settings: {
      tab: {
        overview: 'Overview',
        zones: 'Zones',
        chores: 'Chores',
        account: 'Account',
        users: 'Users'
      }
    },
    community: {
      tab: {
        projects: 'Projects',
        reservations: 'Reservations'
      }
    }
  },

  es: {
    terms: {
      today: 'hoy',
      laterToday: 'más tarde hoy',
      tomorrow: 'mañana',
      yesterday: 'ayer',
      upcoming: 'próximo',
      accountsPayable: 'Cuentas por pagar',
      recordInvoice: 'Grabar factura',
      payInvoice: 'Pagar factura',
      male: 'Macho',
      female: 'Hembra',
      alive: 'Vivo',
      sick: 'Enfermo',
      dead: 'Muerto',
      close: 'Cerrar',
      save: 'Guardar',
      cancel: 'Cancelar',
      ok: 'OK',
      tools: 'Herramientas',
      edit: 'Editar',
      delete: 'Eliminar',
      upload: 'Subir',
      reopen: 'Reabrir',
      danger: 'Peligro',
      tableHeaders: {
        when: 'Cuando',
        subject: 'Sujeto',
        message: 'Mensaje',
        actions: 'Acciones',
        name: 'Nombre',
        next: 'Disponible',
        state: 'Estado',
        opened: 'Abierto',
        closed: 'Cerrado',
        species: 'Tipo',
        breed: 'Raza',
        birth: 'Nacimiento',
        sex: 'Sexo',
        femaleParent: 'Madre',
        age: 'Edad',
        potentiallyPregnant: '¿Embarazada?',
        earMark: 'Crotal',
        lifeStatus: 'Estado',
        usageType: 'Tipo de uso'
      },
      months: {
        january: 'Enero',
        february: 'Febrero',
        march: 'Marzo',
        april: 'Abril',
        may: 'Mayo',
        june: 'Junio',
        july: 'Julio',
        august: 'Agosto',
        september: 'Septiembre',
        october: 'Octubre',
        november: 'Noviembre',
        december: 'Deciembre'
      },
      animalTypes: {
        PIG: 'Cerdo(s)',
        CATTLE: 'Bovino(s)'
      }
    },
    generic: {
      deleteConfirmationDialog: {
        title: 'Confirma que deseas eliminar este elemento',
        text: 'Esta acción no se puede deshacer. Elija sabiamente.',
        item: 'Elemento'
      }
    },
    mainMenu: {
      home: 'Casa',
      shipping: 'Envíos',
      tasks: 'Tareas',
      grazing: 'Pastoreo',
      crop: 'Cultivo',
      irrigation: 'Riego',
      livestock: 'Animales',
      butcher: 'Carnicero',
      sales: 'Shop',
      timesheet: 'Hoja de tiempo',
      accounting: 'Contabilidad',
      zones: 'Zonas',
      customers: 'Clientes',
      language: 'Idioma',
      strips: 'Franjas',
      dashboard: 'Tablero'
    },
    accountingHome: {
      paymentsToSuppliers: 'Pagos a proveedores'
    },
    access: {
      title: 'Acceso a zonas',
      OPEN: 'Abierto',
      CLOSED: 'Cerrado'
    },
    livestock: {
      overview: {
        lifeStatus: {
          title: 'Estado de vida',
          aliveLineOne: '{aliveCount} {type}',
          aliveLineMany: '{aliveCount} {type}',
          sickAppendixOne: ' y {sickCount} esta enfermo',
          sickAppendixMany: ' y {sickCount} son enfermos'
        },
        animalsOnSale: {
          title: 'Animales en venta'
        },
        upcomingEvents: {
          title: 'Próximos eventos'
        }
      }
    },
    inventory: {
      search: 'Buscar en todo',
      addAnimal: 'Añadir animal',
      markReadyForSale: 'Venta',
      sex: {
        FEMALE: 'Hembra',
        MALE: 'Macho'
      },
      tab: {
        overview: 'Reporte situacional',
        inventory: 'Inventario',
        breeding: 'Cria',
        potentialMothers: 'Madres potenciales'
      },
      addAnimalDialog: {
        externalId: 'ID externa',
        type: 'Tipo',
        breed: 'Raza',
        femaleParentId: 'ID madre',
        dateOfBirth: 'Fecha de nacimiento',
        sex: 'Sexo',
        cancel: 'Cancelar',
        save: 'Guardar'
      },
      deleteAnimalDialog: {
        title: '¿Estás seguro de que quieres eliminar este animal?',
        cancel: 'Cancelar',
        ok: 'Si'
      },
      sickDialog: {
        title: '¿Estás seguro de que quieres marcar a este animal como enfermo?',
        comment: 'Commentario',
        cancel: 'Cancelar',
        save: 'Guardar'
      }
    },
    breeding: {
      motherId: 'ID madre',
      daysAfterBirth: 'Días después del nacimiento',
      offspringId: 'ID cria',
      offspringBirth: 'Nacimiento cria',
      offspringAge: 'Edad cria',
      lastOffspringInMonths: 'Desde último nacimiento',
      offsprings: 'Crias'
    },
    customers: {
      search: 'Buscar en todo'
    },
    tasks: {
      search: 'Buscar todo el contenido',
      created: 'Creado',
      assignedTo: 'Asignado a',
      latestComment: 'Último comentario',
      add: 'Crear',
      showDone: 'Hecho',
      showOpen: 'Abierto',
      planned: 'Planeado para',
      toolsNeeded: 'Herramientas necesarias',
      externalURL: 'URL externa para más detalles:',
      tab: {
        overview: 'Resumen',
        tasks: 'Tareas',
        regular: 'Regular',
        zones: 'Zonas',
        tools: 'Herramientas',
        planned: 'Planeado'
      },
      actions: {
        done: 'Hecho',
        block: 'Bloquear',
        unblock: 'Desbloquear',
        assign: 'Asignar',
        unassign: 'Desasignar',
        comment: 'Comentar',
        urgent: 'Urgente',
        resetUrgent: 'Regular',
        plan: 'Planear',
        edit: 'Editar'
      },
      filter: {
        urgent: 'Urgente',
        blocked: 'Bloqueado'
      },
      overview: {
        report: 'Hay {openTasks} tareas abiertas de las cuales {urgentTasks} son urgentes y {blockedTasks} están bloqueadas.'
      },
      tasksPerZone: {
        title: 'Tareas para zona {zoneName}'
      }
    },
    addTaskDialog: {
      title: 'Nueva tarea',
      taskSubject: 'Asunto',
      taskDescription: 'Detalles sobre la tarea',
      taskUrgent: '¿Es urgente?',
      subjectRequired: 'El asunto es obligatorio',
      zoneName: 'Zona',
      toolsNeeded: 'Herramientas necesarias',
      externalURL: 'URL externa para más detalles',
      budget: 'Presupuesto'
    },
    confirmMarkDoneDialog: {
      title: 'Confirmar marcar tarea como completada',
      markAsDone: 'Marcar como hecho'
    },
    blockTaskDialog: {
      title: '¿Bloquear esta tarea?',
      unblockTitle: '¿Desbloquear esta tarea?',
      reason: '¿Cuál es la razón?'
    },
    commentDialog: {
      title: 'Comentar una tarea',
      comment: 'Ingrese el texto para el comentario'
    },
    home: {
      eventHistory: 'Historial de eventos'
    },
    notifications: {
      title: 'Notificaciones',
      task: {
        subject: 'Tareas',
        oneUrgentTask: 'Hay una tarea urgente desde {oldest}',
        urgentWithCount: 'Hay {taskCount} tareas urgentes desde {oldest}',
        oneBlockedTask: 'Hay una tarea bloqueada desde {oldest}',
        blockedWithCount: 'Hay {taskCount} tareas bloqueadas desde {oldest}'
      },
      table: {
        subject: 'Sujeto',
        message: 'Mensaje'
      }
    },
    myGranja: {
      title: 'Mi Granja',
      tasks: {
        table: {
          taskNumber: 'Número de tarea',
          taskSubject: 'Sujeto'
        }
      }
    },
    fotoReport: {
      title: 'Foto reportaje',
      selectZone: 'Seleccionar zona',
      selectPicture: 'Imagen',
      saveReport: 'Reportar'
    },
    confirmDeleteDialog: {
      title: 'Confirma que quieres eliminar esta tarea',
      delete: 'Eliminar tarea'
    },
    reopenTaskDialog: {
      title: 'Reabrir tarea',
      reason: 'Motivo de la reapertura'
    },
    confirmImageDeleteDialog: {
      title: 'Confirma que quieres eliminar esta imagen'
    },
    confirmDeleteImageFromZoneDialog: {
      title: 'Confirma que quieres eliminar esta imagen'
    },
    planTaskDialog: {
      title: 'Planear esta tarea'
    },
    plannedTasks: {
      tasksPlannedFor: 'Tareas planeadas para '
    },
    animalDetails: {
      title: 'Perfil animal',
      EarTag: 'Crotal',
      animalType: 'Tipo de animal',
      animalBreed: 'Raza de animal',
      lifeStatus: 'Estado de vida',
      dateOfBirth: 'Fecha de nacimiento',
      sex: 'Sexo',
      femaleParentId: 'ID de madre',
      statusIndicators: 'Indicadores de estado',
      observations: 'Observaciones',
      medicalHistory: 'Historial médico',
      salesZone: 'Ventas',
      dtOfferForSale: 'Ofrecer este animal a la venta',
      ddForSale: 'Este animal está a la venta desde {forSaleSince}.',
      ddOfferForSale: 'Este animal se puede ofrecer a la venta.',
      btnStopSelling: 'Deja de vender',
      btnStartSelling: 'Empieza a vender',
      dangerZone: 'Zona peligrosa',
      dtDeleteRecord: 'Eliminar el registro de este animal',
      ddDeleteRecord: 'La eliminación de este registro no se puede deshacer. Elegir sabiamente.',
      dtEditAnimal: 'Editar datos de este animal',
      ddEditAnimal: 'Se puede editar parte de la información sobre este animal.',
      btnEditAnimal: 'Editar datos de animal',
      markAsSick: 'Marcar como enfermo',
      markAsHealthy: 'Marcar como recuperado',
      comment: 'Introducir comentario',
      maintenance: {
        title: 'Mantenimiento',
        dtMissingEarTag: 'Pedir crotales faltantes',
        ddMissingEarTag: 'Se pueden pedir crotales faltantes para este animal.',
        btnMissingEarTag: 'Pedir crotales',
        btnEarTagsPutOn: 'Crotales puestos',
        dtEarTagPutOn: 'Crotales puestos',
        ddEarTagPutOn: 'Se pueden marcar los crotales puestos para este animal.'
      }
    },
    orderEarTagsDialog: {
      title: 'Pedir crotales',
      leftEar: 'Oreja izquierda',
      rightEar: 'Oreja derecha'
    },
    putOnEarTagsDialog: {
      title: 'Crotales puestos'
    },
    crop: {
      tab: {
        overview: 'Resumen',
        zoneReport: 'Reporte de zona',
        cropReport: 'Reporte de cultivo'
      }
    },
    grassReport: {
      description: 'Verifique que la marca de mordida en el pasto ya no se vea',
      tableHeaders: {
        name: 'Nombre',
        usage: 'Uso',
        seeded: 'Sembrado',
        germinated: 'Germinado',
        waterNeeded: 'Agua necesaria',
        grazing: 'Pastoreo',
        manureVisible: 'Estiércol visible',
        flowers: 'Flores',
        mushrooms: 'Hongos',
        cropReadyForHarvest: 'Cosecha lista',
        biteMark: 'Marca de mordida'
      }
    },
    grazing: {
      tab: {
        overview: 'Resumen',
        access: 'Acceso',
        report: 'Reporte'
      }
    },
    grazingProposals: {
      title: 'Propuestas de pastoreo',
      description: 'Aquí se muestran las propuestas de pastoreo para el dia de hoy',
      tableHeaders: {
        name: 'Nombre',
        size: 'Tamaño',
        accessClosed: 'Acceso cerrado',
        details: 'Detalles'
      },
      btnShowAll: 'Mostrar todo',
      springRecommendation: 'Estamos en tiempo de primavera ahora. Considere hacer un pase rápido a través de todos los potreros para rozar la punta de la hierba.'
    },
    offspringSection: {
      btnAddOffspring: 'Añadir cría'
    },
    grazingReport: {
      title: 'Reporte de pastoreo',
      zone: 'Zona',
      quality: 'Calidad',
      state: 'Estado',
      opened: 'Abierto',
      closed: 'Cerrado',
      averageSessionDuration: 'Duración media de la sesión'
    },
    cropAlerts: {
      title: 'Alertas de cultivo',
      waterAlerts: 'Alertas de agua'
    },
    grazingStillOpen: {
      title: 'Zonas aún abiertas para pastoreo',
      zoneName: 'Zona',
      openDuration: 'Duración abierta'
    },
    butcher: {
      tab: {
        overview: 'Resumen',
        inventory: 'Inventario',
        pieces: 'Piezas',
        slaughter: 'Matanza',
        billOfLading: 'Albarán',
        carcassReport: 'Reporte de canal'
      },
      slaughter: {
        shipToSlaughterHouse: 'Enviar a matadero'
      }
    },
    sendToSlaughterDialog: {
      title: 'Enviar a matadero',
      prompt: '¿Estás seguro de que quieres enviar estos animales al matadero?'
    },
    irrigation: {
      tab: {
        overview: 'Resumen',
        targets: 'Objetivos',
        sources: 'Fuentes',
        history: 'Historia'
      },
      tableHeaders: {
        time: 'Hora',
        zone: 'Zona',
        object: 'Objeto',
        irrigationType: 'Tipo de riego',
        duration: 'Duración',
        status: 'Estado',
        actualDuration: 'Duración actual'
      }
    },
    irrigationTargets: {
      title: 'Objetivos de riego',
      add: 'Añadir objetivo de riego',
      tableHeaders: {
        zone: 'Zona',
        name: 'Nombre',
        duration: 'Duración',
        daysBetween: 'Días entre',
        irrigationType: 'Tipo de riego',
        source: 'Fuente',
        weight: 'Peso'
      }
    },
    irrigationSources: {
      title: 'Fuentes de riego',
      add: 'Añadir fuente de riego',
      tableHeaders: {
        name: 'Nombre',
        startTime: 'Hora de inicio',
        endTime: 'Hora de finalización'
      }
    },
    strips: {
      tab: {
        overview: 'Resumen',
        stripsList: 'Franjas',
        stripActions: 'Acciones',
        stripActionPackages: 'Paquetes'
      },
      overview: {
        toolbar: {
          add: 'Añadir franja'
        }
      },
      list: {
        details: 'Detalles',
        zone: 'Zona',
        designator: 'Designador',
        description: 'Descripción',
        size: 'Tamaño',
        irrigated: 'Regado',
        complete: 'Completo',
        nextStep: 'Siguiente paso',
        actions: 'Acciones'
      },
      actions: {
        toolbar: {
          add: 'Añadir acción',
          package: 'Paquetes'
        },
        tableHeaders: {
          description: 'Descripción',
          actions: 'Acciones',
          blockTime: 'Dias bloqueados'
        }
      },
      addDialog: {
        title: 'Añadir franja',
        description: 'Descripción',
        size: 'Tamaño en m2',
        irrigated: 'Regado',
        selectZone: 'Seleccionar zona'
      },
      addActionDialog: {
        title: 'Añadir acción',
        description: 'Descripción',
        blockTime: 'Block Time (days)',
        earliestMonth: 'Earliest Month'
      },
      details: {
        title: 'Detalles de franja',
        description: 'Descripción',
        size: 'Tamaño en m2',
        irrigated: 'Regado',
        actions: 'Acciones',
        nextAction: 'Siguiente acción',
        percentComplete: 'Porcentaje completado'
      },
      actionPackages: {
        toolbar: {
          add: 'Añadir paquete'
        }
      },
      addActionPackageDialog: {
        title: 'Añadir paquete de acción',
        packageName: 'Nombre del paquete'
      }
    },
    chores: {
      allDone: 'Todas las tareas diarias están hechas para hoy.',
      tableHeaders: {
        choreDueTime: 'Vencimiento',
        choreSubject: 'Sujeto'
      }
    },
    settings: {
      tab: {
        overview: 'Resumen',
        zones: 'Zonas',
        chores: 'Tareas diarias',
        account: 'Cuenta',
        users: 'Usuarios'
      }
    }
  }
}
